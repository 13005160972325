import React from "react"
import { graphql } from "gatsby"
import SEO from "components/SEO"
import Layout from "components/Layout"
import CaseOverview from "components/CaseOverview"
import Pagination from "components/Pagination"

const Cases = props => {
  const { data, pageContext } = props
  const { casePrefix, title } = data.site.siteMetadata
  const { edges: cases } = data.allWordpressCase

  // console.log("Cases", cases)

  return (
    <Layout location={props.location} navigation>
      <SEO
        seo={{ title: "Cases", description: "Onze cases...", siteName: title }}
      />
      <div className="wrapper">
        <CaseOverview cases={cases} prefix={casePrefix} />
        <Pagination pageContext={pageContext} pathPrefix="/cases" />
      </div>
    </Layout>
  )
}
export default Cases

export const casesQuery = graphql`
  query CasesOverview {
    site {
      siteMetadata {
        title
        casePrefix
      }
    }
    allWordpressCase {
      edges {
        node {
          overview {
            title
            subtitle
          }
          slug
          seo {
            title
            siteName
            description
            image {
              src {
                localFile {
                  childImageSharp {
                    fixed(width: 1280, height: 853, quality: 100) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
          hero {
            image {
              title
              src {
                localFile {
                  childImageSharp {
                    fluid(quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
